<template>
  <div class="container-app">
    <sidebar @closeNav="isNavOpen = false" :is-nav-open="isNavOpen">
      <div class="section">
        <ul class="rightside-sections-bar">
          <h1 class="section_title">Sections</h1>
          <div class="btn btn-primary my-3" @click="submitNotify">EndTest</div>
          <li
            v-for="(section, index) in sections"
            :key="index"
            class="rightside-sections"
          >
            <h3>{{ section.section_name }}</h3>
            <h5 v-if="filters(section.id).length > 4">Scroll Down</h5>
            <hr />

            <div class="grid-container">
              <p
                v-for="(question, index) in filters(section.id)"
                :key="index"
                class="side-questions grid-item"
                @click="browseToQuestion(index, section.id)"
                :style="getStyle(index, section.id)"
              >
                <b>{{ index + 1 }}</b>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </sidebar>
    <div class="container-quiz">
      <Burger
        v-if="isBurgerVisible"
        class="burger"
        v-on:toggleNav="isNavOpen = !isNavOpen"
        :is-active="isNavOpen"
      ></Burger>

      <div
        class="header-quiz d-flex flex-column"
        v-for="(section, index) in sections.slice(
          current_section,
          current_section + 1
        )"
        :key="index"
      >
        <h1>{{ section.section_name }}</h1>
        <h3 v-if="!end">{{ timeLeft }}</h3>
      </div>
      <div class="step-progress" :style="{ width: getProgress + '%' }"></div>
      <div
        class="box"
        v-for="(question, index) in filters(current_section).slice(c, d)"
        :key="index"
        v-show="quiz"
      >
        <div class="box-question">
          <h2>Question {{ c + 1 }}/{{ filters(current_section).length }}(Marks : {{question.marks}})</h2>
          <p>{{ question.question }}</p>
        </div>
        <div class="box-propositions">
          <ul v-show="question.subType == '1' && question.options.length > 0">
            <li
              v-for="(proposition, index) in question.options"
              :key="index"
              class="li"
              @click="selectResponse(proposition.props)"
              :class="{ selected: response == proposition.props }"
            >
              {{ proposition.props }}
            </li>
          </ul>
          <div v-if="!question.subType" id="mytext">
            <textarea
              v-model="response"
              class="form-control"
              maxlength="100"
            ></textarea>
          </div>
          <div v-else-if="mycheck(question)" id="mytext">
            <textarea v-model="response" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="box-score" v-if="end">
        <h2>Completed!!!</h2>
        <div class="btn-restart mybtn">
          <div>
            <button
              @click="submitNotify"
            >
              Submit <i class="fas fa-sync-alt"></i>
            </button>
          </div>
          <div>
            <button>
              <a href="/" @click="redirect">Home </a
              ><i class="fas fa-sync-alt"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="footer-quiz d-flex flex-column">
        <div v-if="!end" class="box-button py-3">
          <button @click="eraseResponse()">Erase</button>
          <button
            @click="nextQuestion()"
            :style="!next ? 'background-color: rgb(106, 128, 202)' : ''"
          >
            Next
          </button>
        </div>
        <div class="py-3" v-if="!end">
          <div class="checkbox">
            <label
              ><input
                class="mx-3"
                type="checkbox"
                value=""
                v-model="marker[f]"
              />
              Mark for Review
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { defineAsyncComponent } from "vue";
import db from "@/firebase";

const Sidebar = defineAsyncComponent(() => import("@/components/Sidebar"));
const Burger = defineAsyncComponent(() => import("@/components/Burger"));

export default {
  name: "Demo Quiz",
  components: {
    Sidebar,
    Burger
  },
  created() {
    window.addEventListener("resize", this.resizeTrigger);
  },
  props: ["id"],
  beforeMount() {
    store.state.loading = true;
  },
  async mounted() {
    this.test_id = this.$route.query.id;

    let questions = (
      await db
        .collection("demo-test")
        .doc(this.test_id)
        .collection("questions")
        .get()
    ).docs.map(d => d.data());

    this.questions = questions;
    let len = this.questions.length;

    db.collection("demo-test")
      .doc(this.test_id)
      .get()
      .then(doc => {
        this.sections = doc.data().sections;
      });
    console.log(questions);
    this.startTimer();
    if (window.innerWidth <= 1000) {
      this.isNavOpen = false;
    } else {
      this.isNavOpen = true;
    }
    store.state.loading = false;
    // this.current_question = this.questions[0];

    let max = -1;
    this.questions.forEach(data => {
      max = Math.max(Number(data.id), max);
    });

    console.log(max);

    for (let i = 0; i <= max; i++) {
      this.marker.push(false);
      this.answers.push("");
    }
  },
  data() {
    return {
      questions: [],
      sections: [],
      i: 0, //original index of question in db
      c: 0, // index of question in that section
      d: 1,
      next: false,
      quiz: true,
      end: false,
      response: "",
      current_section: 0,
      current_question: {},
      answers: [],
      marker: [],
      submitted: false,
      time_left: 1200000,
      isNavOpen: true,
      isBurgerVisible: true,
      test_id: ""
    };
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeTrigger);
  },
  computed: {
    timeLeft() {
      let s = this.time_left;
      var ms = s % 1000;
      s = (s - ms) / 1000;
      var secs = s % 60;
      s = (s - secs) / 60;
      var mins = s % 60;
      var hrs = (s - mins) / 60;

      return hrs + ":" + mins + ":" + secs;
    },
    getProgress() {
      if (this.answers.length == 0) return 0;
      return (100.0 * this.d) / this.filters(this.current_section).length;
    },
    f() {
      var t = +this.i;
      return t;
    }
  },
  methods: {
    resizeTrigger(e) {
      // console.log()
      if (e.currentTarget.outerWidth <= 1000) {
        this.isNavOpen = false;
      } else {
        this.isNavOpen = true;
      }
    },
    startTimer() {
      setInterval(() => this.countdown(), 1000);
    },
    filters(t) {
      if (this.questions)
        return this.questions
          .filter(question => {
            if (question.section == this.sections[t].section_name) return true;
            else return null;
          })
          .sort(function(a, b) {
            return a.id - b.id;
          });
      else return null;
    },
    getStyle(i, sec) {
      var index = +this.filters(sec)[i].id;
      // console.log(index);
      if (
        (this.answers[index] != "" || this.answers[index] != "") &&
        (this.marker[index] == "false" || this.marker[index] == false)
      ) {
        return {
          "background-color": "lightgreen"
        };
      } else if (
        (this.answers[index] != "" || this.answers[index] != "") &&
        (this.marker[index] == "true" || this.marker[index] == true)
      ) {
        return {
          "background-color": "yellow"
        };
      } else if (
        (this.answers[index] == "" || this.answers[index] == "") &&
        (this.marker[index] == "true" || this.marker[index] == true)
      ) {
        return {
          "background-color": "red"
        };
      }
      return "background-color:white";
    },
    submitNotify() {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit your assessment ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.endTest();
        }
      })
    },
    endTest() {
      this.submitQuiz();
      this.end = true;
      this.quiz = false;
    },
    countdown() {
      this.time_left -= 1000;
      if (this.time_left <= 2) {
        //Auto submit here
        this.submitQuiz();
        this.end = true;
        this.quiz = false;
      }
    },
    eraseResponse() {
      this.response = "";
      this.answers[this.i] = "";
      this.$forceUpdate();
    },
    browseToQuestion(index, sec) {
      if (this.end != true) {
        this.c = index;
        this.d = index + 1;
        this.current_section = sec;
        this.current_question = this.filters(this.current_section)[this.c];
        this.i = this.current_question.id;
        this.response = this.answers[+this.i];
        this.next = false;
      } else {
        swal("Submit Test");
      }
    },
    mycheck(ques) {
      this.next = false;
      return ques.options.length == 0;
    },
    selectResponse(x) {
      this.response = x;
      this.answers[this.i] = this.response;
      this.next = false; // For next question
    },
    redirect() {
      this.$router.push();
    },
    nextQuestion() {
      if (this.next) {
        return;
      }

      this.answers[this.i] = this.response; //Pushing some response
      console.log(this.next);
      if (
        this.sections.length - 1 == this.current_section &&
        this.filters(this.current_section).length - 1 == this.c
      ) {
        this.end = true;
        this.quiz = false;
      } else {
        if (this.d >= this.filters(this.current_section).length) {
          this.c = 0;
          this.d = 1;
          this.current_section++;
        } else {
          this.c++;
          this.d++;
        }
        this.current_question = this.filters(this.current_section)[this.c];
        this.i = this.current_question.id;
        this.response = this.answers[+this.i];
      }
      this.$cookie.setCookie("answers" + this.id, this.answers, {
        expires: "30m"
      });
      this.$cookie.setCookie("answers_markers" + this.id, this.marker, {
        expires: "30m"
      });
      this.$cookie.setCookie("lastQuestion" + this.id, this.i, {
        expires: "30m"
      });
      this.$cookie.setCookie("lastSection" + this.id, this.current_section, {
        expires: "30m"
      });
      this.$cookie.setCookie("lastQuestioninSection" + this.id, this.c, {
        expires: "30m"
      });
    },
    submitQuiz() {
      console.log("here");

      this.$router.push({
        name: "DemoResponses",
        query: {
          test_id: this.test_id
        },
        params: {
          questions: JSON.stringify(this.questions),
          response: this.answers,
          sections: JSON.stringify(this.sections)
        }
      });
      this.submitted = true;
    }
  }
};
</script>
<style scoped>
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  letter-spacing: 2px;
}

.container-app {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.container-quiz {
  display: flex;
  width: 40%;
  min-height: 80%;
  background-color: white;
  text-align: center;
  flex-flow: column;
  border: 1px solid #e7eae0;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.header-quiz {
  display: flex;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #e7eae0;
  justify-content: center;
  align-items: center;
  background-color: #f2e3e3;
  border-radius: 10px 10px 0px 0px;
}

p {
  word-wrap: break-word;
}

.container-quiz .box {
  display: flex;
  width: 100%;
  height: 70%;
  flex-flow: column;
  margin: auto;
}

#mytext {
  width: 60%;
}

textarea {
  resize: none;
  width: 100%;
  height: 120px;
}

.box-question {
  margin-top: 20px;
  overflow-y: scroll;
}

.box-question p {
  margin-top: 20px;
}

.box-propositions {
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: center;
}

.box-propositions > ul {
  display: flex;
  width: 80%;
  margin: 0;
  padding: 0;
  flex-flow: column;
}

.box-propositions > ul > li {
  list-style: none;
  line-height: 2;
  border: 1px solid #cdd2d2;
  margin-bottom: 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.3s;
}

.box-propositions > ul > li:hover {
  background-color: #e7eae0;
}

li > div {
  /*float: right;*/
  margin-top: 7px;
  margin-right: 7px;
  /*color: white;*/
}

.selected {
  color: rgb(30, 243, 30);
}

.footer-quiz {
  display: flex;
  width: 100%;

  background-color: #f2e3e3;
  justify-content: center;
  border-top: 1px solid #e7eae0;

  border-radius: 0px 0px 10px 10px;
}

.box-button {
  display: flex;
  width: 100%;
}

.footer-quiz .box-button button {
  width: 150px;
  height: 35px;
  outline: none;
  border: 0;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 15px;
  margin: auto;
  margin-bottom: 10px;
  letter-spacing: 2px;
  background-color: #a09f9ff5;
}

li.correct {
  border: 1px solid rgb(74, 219, 74);
  background-color: rgb(74, 219, 74);
  color: white;
  font-weight: 600;
}

li.incorrect {
  border: 1px solid rgb(240, 117, 100);
  background-color: rgb(240, 117, 100);
  color: white;
  font-weight: 600;
}

.box-score {
  display: flex;
  width: 100%;
  height: 70%;
  flex-flow: column;
}

.box-score h2 {
  margin-top: 40px;
}

i {
  display: none;
  color: white;
}

.step-progress {
  display: flex;
  width: 100%;
  height: 5px;
  background-color: rgb(106, 128, 202);
  transition: 0.5s;
}

.btn-restart {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  margin-top: 50px;
}

.btn-restart button {
  width: 150px;
  height: 35px;
  outline: none;
  border: 0;
  background-color: rgb(106, 128, 202);
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 15px;
  margin: auto;
  margin-bottom: 30px;
  letter-spacing: 2px;
}

.next {
  background-color: rgb(106, 128, 202);
}

@media screen and (max-width: 900px) {
  .container-quiz {
    width: 60%;
  }
}

@media screen and (max-width: 720px) {
  .container-quiz {
    width: 80%;
  }

  .footer-quiz .box-button button {
    width: 100px;
  }
}

.mybtn {
  width: 100%;
  display: block;
}

.section {
  /*position: absolute;*/
  /*right: 0;*/
  width: 100%;
  height: 100%;
  z-index: 10;

  background-color: #f2e3e3;
  box-shadow: 0 0 10px #ddd;
}

.section ul li:first-child {
  border: 0;
}

.rightside-sections-bar {
  list-style: none;
  display: block;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
}

.rightside-sections {
  margin: 20px;
  padding: 20px;
  height: 200px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-track-color: aquamarine;
}

::-webkit-scrollbar {
  display: none;
}

.rightside-sections > h3 {
  position: relative;
}

.side-questions {
  margin: 10px;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 25px;
  border: solid black 1px;
}

b {
  cursor: pointer;
}

a {
  color: white;
}

.burger {
  width: min-content;
  position: absolute;
}

.grid-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}
</style>
